// Apollo Setup Docs - https://www.apollographql.com/docs/react/get-started/

import React, { createContext, useContext, useEffect, Suspense, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink, split, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import * as Sentry from '@sentry/react';
import _sdk from '@hopdrive/sdk';
import { REACT_APP_GQL_SD } from '../utils/env';
import { SimpleLogger } from '../utils/SimpleLogger';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import fetch from 'cross-fetch';
import {
  getUserName,
  getUserEmail,
  getAllowedRoles,
  getAllowedRegions,
  getUserRole,
  hasAccess,
  getUserToken,
} from '../utils/authHelper';
import FirebaseLogin from '../pages/Login/FirebaseLogin';
import DefaultLoadingFallback from '../components/Fallbacks/DefaultLoadingFallback';
import axios from 'axios';

const version = require('../version');

const DataContext = createContext({});

function DataProvider({ children }) {
  const { log } = new SimpleLogger({ prefix: 'DataProvider', enabled: true });

  const firebaseAuth = getAuth();
  const history = useHistory()

  const [firebaseUser, setFirebaseUser] = React.useState(null);
  const [apolloClient, setApolloClient] = React.useState(null);
  const [loading, setLoading] = React.useState(true)
  const [lastLocation, setLastLocation] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [isFullyAuthenticated, setIsFullyAuthenticated] = useState(false);


  //Listen for changes to the logged in Firebase user and set JWT accordingly

  const getFirebaseIdToken = async () => {
    let firebaseToken = await firebaseAuth.currentUser?.getIdToken(true);
    const parsedToken = await firebaseAuth.currentUser?.getIdTokenResult();
    const rawClaims = parsedToken?.claims ? JSON.parse(JSON.stringify(parsedToken?.claims["https://hasura.io/jwt/claims"])) : null;
    if (rawClaims && rawClaims['x-hasura-default-role'] === 'admin') {
      setFirebaseUser(firebaseToken)
    } else {
      setErrorMessage('The user entered does not have access to this application. If you think this is an error, please contact a Hopdrive admin.');
      handleLogout();
    }
      setLoading(false)
  };

  React.useEffect(() => {
    onAuthStateChanged(firebaseAuth, user => {
      if (user) {
        getFirebaseIdToken()
      }
    })
  }, [])


  //If we have a JWT, use it to build out the Sentry user and the user object to be used as context throughout the site
  //Then, initialize Apollo with the JWT
  useEffect(() => {
    const buildSentryUser = async () => {
      return {
        email: await getUserEmail(),
        name: await getUserEmail(),
        nickname: await getUserName(),
        role: await getUserRole(),
        allowed_roles: await getAllowedRoles(),
        allowed_regions: await getAllowedRegions(),
      };
    };

    const SentryUser = buildSentryUser();
    Sentry.setContext('user', SentryUser);

    if (firebaseUser !== null) {
      handleApolloClient();
      setIsFullyAuthenticated(true);
    } else {
      setIsFullyAuthenticated(false);
    }
  }, [firebaseUser]);

  const handleLogout = async () => {
    await firebaseAuth.signOut(firebaseAuth)
    setFirebaseUser(null)
    history.push('/login')
}

  const handleApolloClient = async () => {
    log(`Attempting to initialize Apollo...`);

    //Set up error handler to refresh JWT if it's expired
    const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
          if (extensions && extensions.code === 'invalid-headers') {
            setErrorMessage('Your session encountered an error. Please login again.')
            console.error(`[GraphQL Error]: Invalid headers detected, signing out user`);
            handleLogout();
          }
          if (message && message.includes('JWTExpired')) {
            if (firebaseAuth.currentUser) {
              getFirebaseIdToken()
            } else {
              console.error(`[GraphQL Error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
              setErrorMessage('Your session has expired. Please log in again.');
              handleLogout();
            }
          }
        });
      }

      if (networkError) {
        console.error(`[Network Error]: ${networkError.message}`);
      }
    });

    const wsurl = `wss://${REACT_APP_GQL_SD}.socialautotransport.com/v1/graphql`;
    const httpUrl = `https://${REACT_APP_GQL_SD}.socialautotransport.com/v1/graphql`;

    const httpLink = createHttpLink({
      uri: httpUrl,
      fetch,
    });

    const getHeaders = async () => {
      const clientName = `admin-portal-${version.tag || 'vX.X.X'}`;
      try {
        const token = firebaseUser
        return {
          Authorization: `Bearer ${token}`,
          'hasura-client-name': clientName,
          'x-hasura-role': 'admin'
        };
      } catch (err) {
        console.log('Error retrieving token', err);
      }
    };

    //Setup authlink with id token jwt
    const authLink = setContext(async () => {
      return { headers: await getHeaders() };
    });

    //Web Socket setup (for subscriptions)
    const wsLink = new WebSocketLink({
      uri: wsurl,
      options: {
        lazy: true,
        reconnect: true,
        timeout: 30000,
        connectionParams: async () => {
          return { headers: await getHeaders() };
        },
      },
    });

    const link = split(
      // split based on operation type
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      wsLink,
      authLink.concat(httpLink)
    );

    const client = new ApolloClient({
      link: ApolloLink.from([errorLink, link]),
      cache: new InMemoryCache(),
    });

    log(`Apollo Initialized!`, client);

    //Configure sdk to use apolloClient with user's token
    _sdk.configure({
      apollo_client: client,
    });

    setApolloClient(client);
  };

  const writeUserEventlog = async (email, userId, action) => {
    const token = await getUserToken();
    try {
      const eventlogRes = await axios({
        method: `POST`,
        url: `/.netlify/functions/write-user-event-logs`,
        data: {
          actorEmail: email,
          action: action,
          affectedUserId: userId,
          metadata: null,
        },
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      })
      if (eventlogRes?.status !== 200) {
        console.log('Failed to write user eventlog:', eventlogRes);
        Sentry.captureException(`Failed to write user eventlog: ${eventlogRes}`);
      }
    } catch (err) {
      console.error(`Failed to write user eventlog:`, err);
      Sentry.captureException(`Failed to write user eventlog: ${err}`);
    }
  }

  //Set Context
  const context = {
    apolloClient,
    _sdk: _sdk,
    firebaseUser,
    loading, 
    setLoading,
    lastLocation,
    setLastLocation,
    errorMessage,
    setErrorMessage,
    handleLogout,
    writeUserEventlog,
  };

  DataProvider.displayName = 'DataProvider';

  return (
    <DataContext.Provider value={context}>
      {!isFullyAuthenticated ? (
        <Suspense fallback={<DefaultLoadingFallback />}>
          <FirebaseLogin />
        </Suspense>
      ) : (
        <ApolloProvider client={apolloClient}>
          {children}
        </ApolloProvider>
      )}
    </DataContext.Provider>
  );
}

const useData = () => useContext(DataContext);

export { useData, DataProvider };