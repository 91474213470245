import React from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import { getUserEmail, getUserId, getUserToken } from '../utils/authHelper';
import { useData } from './DataProvider';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import {
  GET_REGIONS,
  GET_ALLOWED_REGIONS,
  GET_CUSTOMERS,
  GET_ALLOWED_CUSTOMERS,
  GET_ALLOWED_PAYER_PAYEES,
  GET_ROLETYPES,
  GET_ALLOWED_ROLES,
  UPSERT_ALLOWED_CUSTOMERS,
  UPSERT_ALLOWED_REGIONS,
  UPSERT_ALLOWED_ROLES,
  SAVE_USER_DETAILS,
  TERMINATE_USER,
  REINSTATE_USER,
  CREATE_USER,
  REMOVE_ALLOWED_CUSTOMER,
  REMOVE_ALLOWED_REGION,
  REMOVE_ALLOWED_ROLE,
  GET_ALLOWED_FEATURES,
  GET_FEATURES,
  UPSERT_ALLOWED_FEATURES,
  REMOVE_ALLOWED_FEATURE,
} from '../utils/gqlUserManagement';

const log = true;

const UserDetailsContext = React.createContext({});

function UserDetailsProvider({ children, user = {}, handleRefetch }) {
  const { apolloClient, firebaseUser, writeUserEventlog } = useData();

  ///////// Set state for the acting logged in user 
  const [adminEmail, setAdminEmail] = React.useState(null);
  const [adminAllowedFeatures, setAdminAllowedFeatures] = React.useState([]);
  const [adminUserId, setAdminUserId] = React.useState(null);

  const getAdminUserInfo = async () => {
    const userId = await getUserId();
    const email = getUserEmail();
    setAdminUserId(userId);
    setAdminEmail(email);
    if (userId) {
      await fetchAllowedFeatures(userId, true);
    }
  }

  React.useEffect(() => {
    if (!firebaseUser || !apolloClient) return;
    getAdminUserInfo();
  }, [firebaseUser])

  // Base State
  const [editMode, setEditMode] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [validation, setValidation] = React.useState({});

  // User Object Fields
  const [active, setActive] = React.useState();
  const [displayName, setDisplayName] = React.useState();
  const [email, setEmail] = React.useState();
  const [defaultRole, setDefaultRole] = React.useState();
  const [avatarUrl, setAvatarUrl] = React.useState();
  const [updatedBy, setUpdatedBy] = React.useState();
  const [phone, setPhone] = React.useState();
  const [userId, setUserId] = React.useState();
  const [driverId, setDriverId] = React.useState();

  // Variables for building permissions
  const [regions, setRegions] = React.useState();
  const [allowedRegions, setAllowedRegions] = React.useState();
  const [allowedRegionIds, setAllowedRegionIds] = React.useState();
  const [originalRegions, setOriginalRegions] = React.useState();
  const [originalRegionIds, setOriginalRegionIds] = React.useState();

  const [customerId, setCustomerId] = React.useState();
  const [customers, setCustomers] = React.useState();
  const [allowedCustomers, setAllowedCustomers] = React.useState();
  const [allowedCustomerIds, setAllowedCustomerIds] = React.useState();
  const [originalCustomers, setOriginalCustomers] = React.useState();
  const [originalCustomerIds, setOriginalCustomerIds] = React.useState();

  const [roles, setRoles] = React.useState();
  const [originalRoleIds, setOriginalRoleIds] = React.useState();
  const [allowedRoleIds, setAllowedRoleIds] = React.useState();

  const [features, setFeatures] = React.useState();
  const [allowedFeatures, setAllowedFeatures] = React.useState();
  const [originalFeatures, setOriginalFeatures] = React.useState();
  const [originalFeatureIds, setOriginalFeatureIds] = React.useState();
  const [allowedFeatureIds, setAllowedFeatureIds] = React.useState();

  const [payers, setPayers] = React.useState();
  const [payees, setPayees] = React.useState();

  //Reset the state when edit mode turned off
  React.useEffect(() => {
    if (!editMode) {
      if (user) {
        setActive(user.active || false);
        setDisplayName(user.display_name || null);
        setEmail(user.email || null);
        setDefaultRole(user.default_role || null);
        setAvatarUrl(user.avatar_url || null);
        setPhone(user.phone || null);
        setUserId(user.id || null);
        setCustomerId(user.customer_id || null);
        setDriverId(user?.driver?.id || null);
        if (originalRegionIds?.length > 0) setAllowedRegionIds(originalRegionIds);
        if (originalRegions?.length > 0) setAllowedRegions(originalRegions);
        if (originalCustomerIds?.length > 0) setAllowedCustomerIds(originalCustomerIds);
        if (originalCustomers?.length > 0) setAllowedCustomers(originalCustomers);
        if (originalFeatureIds?.length > 0) setAllowedFeatureIds(originalFeatureIds);
        if (originalFeatures?.length > 0) setAllowedFeatures(originalFeatures);
      } else {
        setActive(null);
        setDisplayName(null);
        setEmail(null);
        setDefaultRole(null);
        setAvatarUrl(null);
        setPhone(null);
        setUserId(null);
        setCustomerId(null);
        setDriverId(null);
      }
    }
  }, [editMode])


  //Get the base lists of regions, customers, and roles to be used as selection options
  React.useEffect(() => {
    if (!firebaseUser || !apolloClient) return;
    if (!regions || !regions.length) fetchRegions();
    if ((!customers || !customers.length) && customerId) fetchCustomers();
    if (!features || !features.length) fetchFeatures();

    if (!roles || !roles.length) {
      fetchRoles();
    } else {
      if (defaultRole && defaultRole === 'admin') {
        const adminRoleId = roles.find(r => r.name === 'admin').id;
        const driverRoleId = roles.find(r => r.name === 'driver').id;
        setAllowedRoleIds([adminRoleId, driverRoleId]);
      } else if (defaultRole && defaultRole === 'dealer') {
        const dealerRoleId = roles.find(r => r.name === 'dealer').id;
        setAllowedRoleIds([dealerRoleId]);
      } else if (defaultRole && defaultRole === 'dealer-admin') {
        setAllowedRoleIds([roles.find(r => r.name === 'dealer-admin').id]);
      } else if (defaultRole && defaultRole === 'dealer-super-admin') {
        const dealerSuperAdminRoleId = roles.find(r => r.name === 'dealer-super-admin').id;
        const dealerAdminRoleId = roles.find(r => r.name === 'dealer-admin').id;
        setAllowedRoleIds([dealerSuperAdminRoleId, dealerAdminRoleId]);
      }
    }
  }, [defaultRole, customerId, roles, firebaseUser]);

  const fetchRegions = async () => {
    try {
      const res = await apolloClient.query({ query: GET_REGIONS });
      const newRegions = getPropValue(res, `data.regions`);
      if (newRegions && newRegions.length) {
        setRegions(newRegions);
      }
    } catch (err) {
      console.log('error fetching regions', err)
    }
  };

  const fetchRoles = async () => {
    try {
      const res = await apolloClient.query({ query: GET_ROLETYPES });
      const newRoletypes = getPropValue(res, `data.roletypes`);
      if (newRoletypes && newRoletypes.length) {
        setRoles(newRoletypes);
      }
    } catch (err) {
      console.log('error fetching roles', err)
    }
  };

  const fetchCustomers = async () => {
    const res = await apolloClient.query({ query: GET_CUSTOMERS, variables: { customerId } });
    const newCustomers = res?.data?.customers[0]?.organization?.customers;
    if (newCustomers && newCustomers.length) {
      setCustomers(newCustomers);
    }
  };

  const fetchFeatures = async () => {
    const res = await apolloClient.query({ query: GET_FEATURES });
    const newFeatures = getPropValue(res, `data.features`);
    if (newFeatures && newFeatures.length) {
      setFeatures(newFeatures);
    }
  };

  // Set state on user upon receiving prop
  React.useEffect(() => {
    if (user) {
      setActive(user.active || false);
      setDisplayName(user.display_name || null);
      setEmail(user.email || null);
      setDefaultRole(user.default_role || null);
      setAvatarUrl(user.avatar_url || null);
      setPhone(user.phone || null);
      setUserId(user.id || null);
      setCustomerId(user.customer_id || null);
      setDriverId(user?.driver?.id || null);
    }
  }, [user]);

  //Look up the permissions that are relevant for each allowed role
  React.useEffect(() => {
    if (userId && apolloClient) {
      fetchAllowedRoles(user.id);
      fetchAllowedRegions(userId);
      fetchAllowedCustomers(userId);
      fetchAllowedFeatures(userId);
    }

    // if (customerId) {
    //   fetchAllowedPayees(customerId);
    //   fetchAllowedPayers(customerId);
    // }

  }, [userId]);

  React.useEffect(() => {
    if (defaultRole && defaultRole === 'dealer') {
      setAllowedCustomerIds([])
    }
  }, [defaultRole])

  const fetchAllowedRoles = async userId => {
    let originalRoles = [];
    const res = await apolloClient.query({ query: GET_ALLOWED_ROLES, variables: { userId } });
    const userToRoles = getPropValue(res, `data.usertoroles`);
    if (userToRoles && userToRoles.length > 0) {
      //Store initial allowed roles so that later we can check if one has been removed and we need to delete it
      userToRoles.forEach(role => {
        originalRoles.push(role.role_id);
      });
      setOriginalRoleIds(originalRoles);
    }
  };

  const fetchAllowedRegions = async userId => {
    let originalRegionsList = [];
    const res = await apolloClient.query({ query: GET_ALLOWED_REGIONS, variables: { userId } });
    const userToRegions = getPropValue(res, `data.usertoregions`);
    if (userToRegions && userToRegions.length > 0) {
      setAllowedRegions(userToRegions);
      setOriginalRegions(userToRegions);
      //Store initial allowed regions so that later we can check if one has been removed and we need to delete it
      userToRegions.forEach(region => {
        originalRegionsList.push(region.region_id);
      });
      setOriginalRegionIds(originalRegionsList);
      setAllowedRegionIds(originalRegionsList);
    }
  };

  const fetchAllowedCustomers = async userId => {
    let originalCustomersList = [];
    const res = await apolloClient.query({ query: GET_ALLOWED_CUSTOMERS, variables: { userId } });
    const userToCustomers = getPropValue(res, `data.usertocustomers`);
    //Store initial allowed customers so that later we can check if one has been removed and we need to delete it
    if (userToCustomers && userToCustomers.length > 0) {
      setAllowedCustomers(userToCustomers);
      setOriginalCustomers(userToCustomers);
      userToCustomers.forEach(customer => {
        originalCustomersList.push(customer.customer_id);
      });
      setOriginalCustomerIds(originalCustomersList);
      setAllowedCustomerIds(originalCustomersList)
    }
  };

  const fetchAllowedPayees = async customerId => {
    let allowedPayees = [];
    const res = await apolloClient.query({ query: GET_ALLOWED_PAYER_PAYEES, variables: { customerId } });
    const payerToCustomer = getPropValue(res, `data.payertocustomer`);
    if (payerToCustomer && payerToCustomer.length > 0) {
      payerToCustomer.forEach(p => {
        if (p.payer_id === customerId) {
          allowedPayees.push(p);
        }
      });
    }
    setPayees(allowedPayees);
  };

  const fetchAllowedPayers = async customerId => {
    let allowedPayers = [];
    const res = await apolloClient.query({ query: GET_ALLOWED_PAYER_PAYEES, variables: { customerId } });
    const payerToCustomer = getPropValue(res, `data.payertocustomer`);
    if (payerToCustomer && payerToCustomer.length > 0) {
      payerToCustomer.forEach(p => {
        if (p.payee_id === customerId) {
          allowedPayers.push(p);
        }
      });
    }
    setPayers(allowedPayers);
  };

  const fetchAllowedFeatures = async (userId, isAdmin = false) => {
    let originalFeaturesList = [];
    const res = await apolloClient.query({ query: GET_ALLOWED_FEATURES, variables: { userId } });
    const userToFeatures = getPropValue(res, `data.usertofeatures`);
    if (userToFeatures && userToFeatures.length > 0) {
      if (isAdmin) {
        setAdminAllowedFeatures(userToFeatures);
      } else {
        setAllowedFeatures(userToFeatures);
        setOriginalFeatures(userToFeatures);
        userToFeatures.forEach(feature => {
          originalFeaturesList.push(feature.feature_id);
        });
        setOriginalFeatureIds(originalFeaturesList);
        setAllowedFeatureIds(originalFeaturesList);
      }
    }
  };


  const getFeatureName = (feature) => {
    let featureName = feature?.name;
    if (featureName.startsWith('dispatching-')) {
      featureName = featureName.replace('dispatching-', '');
    }
    featureName = featureName.replace(/-/g, ' ');
    featureName = featureName.replace(/\b\w/g, char => char.toUpperCase());
    return featureName;
  };


  //Ensure that when a user is given higher-tier access to a category, they are also given access to the basic features
  const handleSelectedFeatures = (selections) => {
    const selectedFeaturesWithDefaults = [...selections];
    
    // First, check if any features were removed that shouldn't have been
    const previousFeatures = allowedFeatures || [];
    const removedFeatures = previousFeatures.filter(prev => {
      const prevId = prev.feature_id || prev.id;
      return !selections.some(sel => (sel.feature_id || sel.id) === prevId);
    });

    // Check if any removed features have dependent features
    const hasRemovedBaseFeature = removedFeatures.some(removed => {
      if (!removed?.category_id && !removed?.feature?.category_id) {
        const featureId = removed?.feature_id || removed?.id;
        const hasDependentFeature = selectedFeaturesWithDefaults.some(selected => {
          return (selected?.category_id || selected?.feature?.category_id) === featureId;
        });
        return hasDependentFeature;
      }
    });

    if (hasRemovedBaseFeature) {
      setAllowedFeatures(previousFeatures);
      setAllowedFeatureIds(previousFeatures.map(f => f.feature_id || f.id));
      return;
    }

    // Add base features for any selected subfeatures
    const allFeatures = features || [];
    selections.forEach(selected => {
      const categoryId = selected?.category_id || selected?.feature?.category_id;
      if (categoryId) {
        // Find the base feature that matches the category_id
        const baseFeature = allFeatures.find(f => f.id === categoryId);
        if (baseFeature) {
          // Check if base feature is already included
          const isBaseFeatureIncluded = selectedFeaturesWithDefaults.some(f => 
            (f.feature_id || f.id) === baseFeature.id
          );
          
          if (!isBaseFeatureIncluded) {
            selectedFeaturesWithDefaults.push({
              feature_id: baseFeature.id,
              feature: baseFeature
            });
          }
        }
      }
    });

    setAllowedFeatures(selectedFeaturesWithDefaults);
    setAllowedFeatureIds(selectedFeaturesWithDefaults.map(f => f.feature_id || f.id));
  }

  //Generate a comparison between old and new permissions
  const getPermissionsDelta = () => {
    const removedCustomers = findRecordsToRemove(originalCustomerIds, allowedCustomerIds);
    const addedCustomers = findRecordsToAdd(originalCustomerIds, allowedCustomerIds);

    const removedRegions = findRecordsToRemove(originalRegionIds, allowedRegionIds);
    const addedRegions = findRecordsToAdd(originalRegionIds, allowedRegionIds);

    const removedRoles = findRecordsToRemove(originalRoleIds, allowedRoleIds);
    const addedRoles = findRecordsToAdd(originalRoleIds, allowedRoleIds);

    const removedFeatures = findRecordsToRemove(originalFeatureIds, allowedFeatureIds);
    const addedFeatures = findRecordsToAdd(originalFeatureIds, allowedFeatureIds);

    const permissionsDelta = {}
    if (removedCustomers.length > 0) permissionsDelta.removedCustomers = removedCustomers;
    if (addedCustomers.length > 0) permissionsDelta.addedCustomers = addedCustomers;
    if (removedRegions.length > 0) permissionsDelta.removedRegions = removedRegions;
    if (addedRegions.length > 0) permissionsDelta.addedRegions = addedRegions;
    if (removedRoles.length > 0) permissionsDelta.removedRoles = removedRoles;
    if (addedRoles.length > 0) permissionsDelta.addedRoles = addedRoles;
    if (removedFeatures.length > 0) permissionsDelta.removedFeatures = removedFeatures;
    if (addedFeatures.length > 0) permissionsDelta.addedFeatures = addedFeatures;
    return permissionsDelta;
  }

  const findRecordsToAdd = (original, current) => {
    let newIds = [];
    current && current.length > 0 && current.forEach(c => {
      if (original && original.length > 0 && !original.includes(c)) {
        newIds.push(c);
      }
    });
    return newIds;
  }

  const writePermissionsEventlog = async (email, userId, action, metadata) => {
    const permissionsDelta = metadata ? getPermissionsDelta() : null;
    const token = await getUserToken();
      try {
        const eventlogRes = await axios({
          method: `POST`,
          url: `/.netlify/functions/write-user-event-logs`,
          data: {
            actorEmail: email,
            action: action,
            affectedUserId: userId,
            metadata: permissionsDelta,
          },
          headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${token}`,
          },
        })
        if (eventlogRes?.status !== 200) {
          console.log('Failed to write user eventlog:', eventlogRes);
          Sentry.captureException(`Failed to write user eventlog: ${eventlogRes}`);
        }
      } catch (err) {
        console.error(`Failed to write user eventlog:`, err);
        Sentry.captureException(`Failed to write user eventlog: ${err}`);
      }
    }

  //Determine if any allowances were removed during the editing session
  const findRecordsToRemove = (original, current) => {
    let missingIds = [];
    original && original.length > 0 && original.forEach(o => {
      if (current && current.length > 0 && !current.includes(o)) {
        missingIds.push(o);
      } else if (!current || current.length === 0) {
        missingIds.push(o);
      }
    });
    return missingIds;
  };

  //First, we'll want to save changes to the user's allowances so that when we
  // update the user record itself,Firebase claims will be built with the most up-to-date values
  const [upsertAllowedRoles] = useMutation(UPSERT_ALLOWED_ROLES);
  const [upsertAllowedRegions] = useMutation(UPSERT_ALLOWED_REGIONS);
  const [upsertAllowedCustomers] = useMutation(UPSERT_ALLOWED_CUSTOMERS);
  const [upsertAllowedFeatures] = useMutation(UPSERT_ALLOWED_FEATURES);
  const [removeAllowedRegion] = useMutation(REMOVE_ALLOWED_REGION);
  const [removeAllowedRole] = useMutation(REMOVE_ALLOWED_ROLE);
  const [removeAllowedCustomer] = useMutation(REMOVE_ALLOWED_CUSTOMER);
  const [removeAllowedFeature] = useMutation(REMOVE_ALLOWED_FEATURE);

  const validateForm = (role) => {
    let valid = true;

    const isValidDisplayName = username => {
      if (!username || username?.length < 2) {
        return false;
      } else return true;
    };

    const isValidEmail = email => {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailPattern.test(email?.toLowerCase());
    };

    const isValidUrl = url => {
      const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
      return urlPattern.test(url);
    };

    const isValidPhoneNumber = phoneNumber => {
      const digitsOnly = phoneNumber.replace(/\D/g, '');
      const phonePattern = /^\d{10}$/;

      return phonePattern.test(digitsOnly);
    };

    const isRegionsValid = (allowedRegions) => {
      if (role === 'admin') {
        if (!allowedRegions || !allowedRegions.length || !allowedRegions.length > 0) {
          return false
        }
      }
      return true
    }

    const isCustomersValid = (allowedCustomers) => {
      if (defaultRole && (defaultRole === 'dealer-admin' || defaultRole === 'dealer-super-admin')) {
        if (!allowedCustomers || !allowedCustomers.length || !allowedCustomers.length > 0) {
          return false
        }
      }

      return true
    }

    const isCustomerValid = (customerId) => {
      if (defaultRole && (defaultRole === 'dealer-admin' || defaultRole === 'dealer' || defaultRole === 'dealer-super-admin')) {
        if (!customerId) {
          return false
        }
      }
      return true
    }

    const newValidation = {
      displayName: isValidDisplayName(displayName),
      email: isValidEmail(email),
      avatar: avatarUrl ? isValidUrl(avatarUrl) : true,
      phone: phone ? isValidPhoneNumber(phone) : true,
      defaultRole: role === 'admin' ? true : defaultRole ? true : false,
      customer: isCustomerValid(customerId),
      regions: role.includes('dealer') ? true : isRegionsValid(allowedRegions),
      customers: isCustomersValid(allowedCustomers)
    };

    setValidation(newValidation);

    Object.keys(newValidation).forEach((key, i) => {
      const value = Object.values(newValidation)[i];
      if (value === false) {
        valid = false;
        setIsSaving(false);
      }
    });

    return { valid: valid, validation: newValidation };
  };

  //If the form is valid: 
  // 1) Remove allowances as needed
  // 2) Insert new allowances
  // 3) Update the user record, which additionally will trigger a build of Firebase claims based on the allowances
  const handleSave = async (role) => {
    setIsSaving(true);

    const formValidation = validateForm(role);

    if (!formValidation.valid) {
      if (formValidation.validation.customers === false) {
        toast.error(`Dealer-admins must have at least one allowed customer.`);
      } else if (defaultRole === 'admin' && formValidation.validation.regions === false) {
        toast.error(`Admins must have at least one allowed region.`);
      } else if (
        formValidation.validation.defaultRole === false ||
        formValidation.validation.email === false ||
        formValidation.validation.avatar === false ||
        formValidation.validation.phone === false ||
        formValidation.validation.customer === false
      ) {
        toast.error(`Failed to save. Please check the form for errors.`);
      }
    } else {
      let regionsToRemove;
      let customersToRemove;
      let rolesToRemove;
      let featuresToRemove;
      const eventlogRes = await writePermissionsEventlog(adminEmail, userId, 'user.permissions.updated', true);

      if (
        originalRegionIds &&
        originalRegionIds.length > 0
      ) {
        regionsToRemove = findRecordsToRemove(originalRegionIds, allowedRegionIds);
      }

      if (
        allowedRoleIds &&
        allowedRoleIds.length &&
        allowedRoleIds.length > 0 &&
        originalRoleIds &&
        originalRoleIds.length > 0
      ) {
        rolesToRemove = findRecordsToRemove(originalRoleIds, allowedRoleIds);
      }

      if (
        allowedCustomers &&
        allowedCustomers.length &&
        allowedCustomers.length > 0 &&
        originalCustomerIds &&
        originalCustomerIds.length > 0
      ) {
        customersToRemove = findRecordsToRemove(originalCustomerIds, allowedCustomerIds);
      }

      if (originalFeatureIds && originalFeatureIds.length > 0) {
        featuresToRemove = findRecordsToRemove(originalFeatureIds, allowedFeatureIds);
      }

      try {
        if (regionsToRemove && regionsToRemove.length > 0) {
          for (let i = 0; i < regionsToRemove.length; i++) {
            const res = await removeAllowedRegion({ variables: { regionId: regionsToRemove[i], userId: userId } });
          }
        }

        if (rolesToRemove && rolesToRemove.length > 0) {
          for (let i = 0; i < rolesToRemove.length; i++) {
            const res = await removeAllowedRole({ variables: { roleId: rolesToRemove[i], userId: userId } });
          }
        }

        if (customersToRemove && customersToRemove.length > 0) {
          for (let i = 0; i < customersToRemove.length; i++) {
            const res = await removeAllowedCustomer({
              variables: { customerId: customersToRemove[i], userId: userId },
            });
          }
        }

        if (featuresToRemove && featuresToRemove.length > 0) {
          for (let i = 0; i < featuresToRemove.length; i++) {
            const res = await removeAllowedFeature({ variables: { featureId: featuresToRemove[i], userId: userId } });
          }
        }
      } catch (err) {
        console.log('Error removing allowances', err);
      }

      const allowedRegionsObj =
        allowedRegions &&
        allowedRegions.length &&
        allowedRegions.length > 0 &&
        allowedRegions.map(r => {
          return {
            region_id: r.region_id || r.id,
            user_id: user.id,
            updated_at: 'now()',
            updated_by: adminEmail,
            created_at: 'now()',
            created_by: adminEmail,
          };
        });

      const allowedCustomersObj =
        allowedCustomers &&
        allowedCustomers.length &&
        allowedCustomers.length > 0 &&
        allowedCustomers.map(c => {
          return {
            customer_id: c.customer_id || c.id,
            user_id: user.id,
            updated_at: 'now()',
            updated_by: adminEmail,
            created_at: 'now()',
            created_by: adminEmail,
          };
        });

      const allowedRolesObj =
        allowedRoleIds &&
        allowedRoleIds.length &&
        allowedRoleIds.length > 0 &&
        allowedRoleIds.map(r => {
          return {
            role_id: r,
            user_id: user.id,
            updated_at: 'now()',
            updated_by: adminEmail,
            created_at: 'now()',
            created_by: adminEmail,
          };
        });

      const allowedFeaturesObj =
        allowedFeatures &&
        allowedFeatures.length &&
        allowedFeatures.length > 0 &&
        allowedFeatures.map(f => {
          return {
            feature_id: f.feature_id || f.id,
            user_id: user.id,
            updated_at: 'now()',
            updated_by: adminEmail,
            created_at: 'now()',
            created_by: adminEmail,
          };
        });

      try {
        if (allowedRegionsObj) {
          const res = await upsertAllowedRegions({ variables: { allowedRegionsObj: allowedRegionsObj } });
        }
        if (allowedCustomersObj) {
          const res = await upsertAllowedCustomers({ variables: { allowedCustomersObj: allowedCustomersObj } });
        }
        if (allowedRolesObj) {
          const res = await upsertAllowedRoles({ variables: { allowedRolesObj: allowedRolesObj } });
        }
        if (allowedFeaturesObj) {
          const res = await upsertAllowedFeatures({ variables: { allowedFeaturesObj: allowedFeaturesObj } });
        }
      } catch (err) {
        console.log('Error upserting new allowances:', err);
        toast.error('Failed to update allowances. User permissions could not be rebuilt');
        setIsSaving(false);
      }

      try {
        const saveUserRes = await handleSaveUserChanges(user.id, role);
        setIsSaving(false);
      } catch (err) {
        console.log('Failed to update user', err);
        setIsSaving(false);
      }
    }
  };

  // Once the allowances are saved, we'll update the user record. (Firebase claims are triggered by updates to the user record.)
  const [saveUser] = useMutation(SAVE_USER_DETAILS);
  // Insert new user record into db
  const [createUser] = useMutation(CREATE_USER);

  const handleSaveUserChanges = async (userId, role) => {
    setIsSaving(true);

    try {
      const variables = {
        userId: userId || null,
        userObj: {
          active: active || true,
          email: email ? email.trim().toLowerCase() : null,
          phone: phone ? phone.trim() : null,
          avatar_url: avatarUrl ? avatarUrl : null,
          default_role: role === 'admin' ? 'admin' : defaultRole && role !== 'admin' ? defaultRole : null,
          display_name: displayName ? displayName : null,
          updated_at: 'now()',
          customer_id: role === 'admin' ? 9 : role !== 'admin' && customerId ? customerId : null,
        },
      };

      const res = await saveUser({ variables });
      if (res && res.data) {
        if (getPropValue(res, `data.update_users.affected_rows`) > 0) {
          log && console.log(`Successfully updated user record:`, res.data.update_users);
          toast.success(`Successfully updated User!`);

          //The update user --> upsert permissions --> update user again timing is pretty delicate, and there's a timing problem such that requerying for allowed customers/regions still will still return the original list and not the updated one.
          //So, we'll just set the state to the new values we just saved.
          let newRegions = res.data.update_users.returning[0]?.users_usertoregions;
          setAllowedRegions(newRegions);
          setOriginalRegions(newRegions);
          let newRegionIds = newRegions.map(r => r.region_id);
          setAllowedRegionIds(newRegionIds);
          setOriginalRegionIds(newRegionIds);

          let newCustomers = res.data.update_users.returning[0]?.users_usertocustomers;
          setAllowedCustomers(newCustomers);
          setOriginalCustomers(newCustomers);
          let newCustomerIds = newCustomers.map(c => c.customer_id);
          setAllowedCustomerIds(newCustomerIds);
          setOriginalCustomerIds(newCustomerIds);

          let newFeatures = res.data.update_users.returning[0]?.users_usertofeatures;
          setAllowedFeatures(newFeatures);
          setOriginalFeatures(newFeatures);
          let newFeatureIds = newFeatures.map(f => f.feature_id);
          setAllowedFeatureIds(newFeatureIds);
          setOriginalFeatureIds(newFeatureIds);

          setEditMode(false);
          return true
        } else {
          toast.error(`Failed to update user!`);
          console.error(`Failed to update user!`);
          setEditMode(false);
          return false
        }
      }
    } catch (err) {
      toast.error(`Failed to update user!`);
      console.error(`Failed to update user:`, err);
      setEditMode(false);
      return false
    }
    setIsSaving(false);
  };

  const upsertPermissions = async userId => {
    const allowedRegionsObj =
      allowedRegions &&
      allowedRegions.length &&
      allowedRegions.length > 0 &&
      allowedRegions.map(r => {
        return {
          region_id: r.region_id || r.id,
          user_id: userId,
          updated_at: 'now()',
          updated_by: adminEmail,
          created_at: 'now()',
          created_by: adminEmail,
        };
      });

    const allowedCustomersObj =
      allowedCustomers &&
      allowedCustomers.length &&
      allowedCustomers.length > 0 &&
      allowedCustomers.map(c => {
        return {
          customer_id: c.customer_id || c.id,
          user_id: userId,
          updated_at: 'now()',
          updated_by: adminEmail,
          created_at: 'now()',
          created_by: adminEmail,
        };
      });

    const allowedRolesObj =
      allowedRoleIds &&
      allowedRoleIds.length &&
      allowedRoleIds.length > 0 &&
      allowedRoleIds.map(r => {
        return {
          role_id: r,
          user_id: userId,
          updated_at: 'now()',
          updated_by: adminEmail,
          created_at: 'now()',
          created_by: adminEmail,
        };
      });

    const allowedFeaturesObj =
      allowedFeatures &&
      allowedFeatures.length &&
      allowedFeatures.length > 0 &&
      allowedFeatures.map(f => {
        return {
          feature_id: f.feature_id || f.id,
          user_id: userId,
          updated_at: 'now()',
          updated_by: adminEmail,
          created_at: 'now()',
          created_by: adminEmail,
        };
      });

    try {
      if (allowedRegionsObj) {
        const res = await upsertAllowedRegions({ variables: { allowedRegionsObj: allowedRegionsObj } });
      }
      if (allowedCustomersObj) {
        const res = await upsertAllowedCustomers({ variables: { allowedCustomersObj: allowedCustomersObj } });
      }
      if (allowedRolesObj) {
        const res = await upsertAllowedRoles({ variables: { allowedRolesObj: allowedRolesObj } });
      }
      if (allowedFeaturesObj) {
        const res = await upsertAllowedFeatures({ variables: { allowedFeaturesObj: allowedFeaturesObj } });
      }
      return true
    } catch (err) {
      console.log('Error upserting new allowances:', err);
      toast.error('Failed to update allowances. User permissions could not be rebuilt');
      return false
    }
  };

  const handleCreateUser = async (role) => {
    setIsSaving(true);

    const formValidation = validateForm(role);

    if (!formValidation.valid) {
      if (formValidation.validation.customers === false) {
        toast.error(`Dealer-admins must have at least one allowed customer.`);
      } else if (formValidation.validation.regions === false) {
        toast.error(`Admins and dispatchers must have at least one allowed region.`);
      } else if (formValidation.validation.defaultRole === false) {
        toast.error(`Please select a role.`);
      } else if (formValidation.validation.email === false) {
        toast.error(`Please enter a valid email.`);
      } else if (formValidation.validation.phone === false) {
        toast.error(`Please enter a valid phone number.`);
      } else if (formValidation.validation.displayName === false) {
        toast.error(`Please enter a valid display name`)
      } else if
        (formValidation.validation.displayName === false) {
        toast.error(`Please enter a valid display name`)
      } else {
        toast.error(`Form could not be validated. Please check for errors.`);
      }
    } else {
      try {
        const variables = {
          userObj: {
            active: true,
            email: email ? email.toLowerCase().trim() : null,
            phone: phone ? phone.trim() : null,
            avatar_url: avatarUrl ? avatarUrl : null,
            default_role: role === 'admin' ? 'admin' : role !== 'admin' && defaultRole ? defaultRole : null,
            display_name: displayName ? displayName : null,
            created_at: 'now()',
            updated_at: 'now()',
            customer_id: role === 'admin' ? 9 : role !== 'admin' && customerId ? customerId : null,
          },
        };

        const res = await createUser({ variables });
        if (res?.data) {
          if (getPropValue(res, `data.insert_users.affected_rows`) > 0) {
            const newUsers = getPropValue(res, `data.insert_users.returning`);
            const newUserId = newUsers && newUsers.length && newUsers[0].id ? newUsers[0].id : null;
            
            // Wait for all operations to complete before setting userId
            await Promise.all([
              upsertPermissions(newUserId),
              handleSaveUserChanges(newUserId, role),
              writeUserEventlog(adminEmail, newUserId, 'user.created', false)
            ]);

            // Set userId only after all operations are complete
            setUserId(newUserId);
            
            toast.success(`Successfully created User ${newUserId}!`);
            return { success: true, userId: newUserId }
          }
        }
      } catch (err) {
        if (err.toString().includes('duplicate key value violates unique constraint')) {
          toast.error(`User with email ${email} already exists!`);
          return { success: false }
        } else {
          toast.error(`Failed to create user!`);
          console.error(`Failed to create user:`, err);
          return { success: false }
        }
      }
      setIsSaving(false);
    }
  };

  //Gear menu actions
  const terminateUser = async userId => {
    const res = await apolloClient.mutate({ mutation: TERMINATE_USER, variables: { userId } });
    const terminatedUser = getPropValue(res, `data.update_users.affected_rows`);
    if (terminatedUser) {
      return true;
    } else return false;
  };

  const reinstateUser = async userId => {
    const res = await apolloClient.mutate({ mutation: REINSTATE_USER, variables: { userId } });
    const reinstatedUser = getPropValue(res, `data.update_users.affected_rows`);
    if (reinstatedUser) {
      return true;
    } else return false;
  };

  // Check if a user has a feature
  const hasFeature = featureName => {
    const feature = adminAllowedFeatures?.find(f => f?.feature.name === featureName);
    return feature ? true : false;
  };

  // Set Context
  const context = {
    // Base State
    editMode,
    setEditMode,
    isSaving,
    setIsSaving,
    regions,
    setRegions,

    // Edit Vars
    active,
    phone,
    defaultRole,
    email,
    displayName,
    avatarUrl,
    updatedBy,
    allowedRegions,
    allowedCustomers,
    allowedFeatures,
    payers,
    payees,
    customers,
    roles,
    features,
    allowedRegionIds,
    allowedCustomerIds,
    allowedRoleIds,
    allowedFeatureIds,
    validation,
    customerId,
    userId,
    driverId,
    adminAllowedFeatures,
    adminUserId,
    adminEmail,

    // Set Edit Vars
    setActive,
    setDefaultRole,
    setEmail,
    setPhone,
    setDisplayName,
    setUpdatedBy,
    setAvatarUrl,
    setAllowedRegions,
    setAllowedCustomers,
    setAllowedFeatures,
    setPayers,
    setPayees,
    setCustomers,
    setRoles,
    setFeatures,
    setCustomerId,
    setAllowedRegionIds,
    setAllowedCustomerIds,
    setAllowedRoleIds,
    setAllowedFeatureIds,
    setValidation,
    setDriverId,

    // Handler Functions
    handleSelectedFeatures,
    handleSaveUserChanges,
    terminateUser,
    reinstateUser,
    handleSave,
    handleCreateUser,
    handleRefetch,
    getFeatureName,

    // Check Functions
    hasFeature,
  };

  return <UserDetailsContext.Provider value={context}>{children}</UserDetailsContext.Provider>;
}

const useUserDetails = () => React.useContext(UserDetailsContext);

export { useUserDetails, UserDetailsProvider };
