import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    default: {
      main: `#323640`,
      light: `#646c80`,
      lighter: `#a0a8b4`,
      dark: `#16181c`,
      contrastText: `#ffffff`,
    },

    primary: {
      darker: `#802418`,
      dark: `#cc3a26`,
      main: `#ff4830`,
      light: `#ff6d59`,
      lighter: `#ffa498`,
      contrastText: `#fff`,
    },
    secondary: {
      darker: `#203450`,
      dark: `#335380`,
      main: `#4068a0`,
      light: `#6686b3`,
      lighter: `#a0b4d0`,
      contrastText: `#fff`,
    },
    tertiary: {
      darker: `#205058`,
      dark: `#33808d`,
      main: `#40a0b0`,
      light: `#66b3c0`,
      lighter: `#a0d0d8`,
      contrastText: `#fff`,
    },

    utility: {
      darker: `#202430`,
      dark: `#333a4d`,
      main: `#404860`,
      light: `#666d80`,
      lighter: `#a0a4b0`,
      contrastText: `#fff`,
    },
    error: {
      darker: `#801020`,
      dark: `#cc1a33`,
      main: `#ff2040`,
      light: `#ff4d66`,
      lighter: `#ff90a0`,
      contrastText: `#fff`,
    },
    warning: {
      darker: `#805018`,
      dark: `#cc8026`,
      main: `#ffa030`,
      light: `#ffb359`,
      lighter: `#ffd098`,
      contrastText: `#fff`,
    },
    success: {
      darker: `#185a24`,
      dark: `#26903a`,
      main: `#30b448`,
      light: `#59c36d`,
      lighter: `#98daa4`,
      contrastText: `#fff`,
    },
    info: {
      darker: `#104080`,
      dark: `#1a66cc`,
      main: `#2080ff`,
      light: `#4d99ff`,
      lighter: `#90c0ff`,
      contrastText: `#fff`,
    },

    nav: {
      prod: `#202430`,
      test: `#666d80`,
    },

    ops: {
      main: `#f05020`,
    },
    concierge: {
      main: `#4080c8`,
      loaner: `#72b0e0`,
    },
    auto: {
      main: `#20c880`,
    },
    lyft: {
      main: `#ea0b8c`,
    },
    uber: {
      main: `#000000`,
    },
    android: {
      main: `#00de7a`,
    },
    ios: {
      main: `#b3b3b3`,
    },

    text: {
      primary: `#323640`,
      secondary: `#32364096`,
      disabled: `#32364072`,
      hint: `#32364072`,
      contrast: `#ffffff`,
      contrastFade: `#ffffff96`,
      contrastPrimary: `#ffffff`,
      contrastSecondary: `#ffffffa0`,
      contrastDisabled: `#ffffff72`,
    },

    background: {
      paper: `#ffffff`,
      default: `#fafafa`,
      light: `#f8f8f8`,
      main: `#f4f4f4`,
      dark: `#f0f0f0`,
    },

    action: {
      hover: `#00000010`,
      selected: `#00000020`,
      focus: `#00000030`,
      active: `#00000080`,
      disabled: `#00000060`,
      disabledBackground: `#00000030`,
    },

    divider: `#00000016`,
    dividerStrong: `#e0e0e0`,
    drop: `#32364010`,

    fade: [
      `#00000010`,
      `#00000020`,
      `#00000030`,
      `#00000040`,
      `#00000050`,
      `#00000060`,
      `#00000080`,
      `#000000a0`,
      `#000000b4`,
      `#000000c8`,
    ],
  },

  border: [`1px solid #00000020`, `1px solid #00000040`, `1px solid #00000060`, `1px solid #e0e0e0`],

  shape: {
    borderRadius: `4px`,
    paperRadius: `8px`,
  },

  shadow: {
    none: `none`,
    soft: `0 0 16px #00000016`,
    medium: `0 0 16px #00000024`,
    harsh: `0 0 16px #00000040`,
    nav: `0 0 16px #00000040`,
    sharp: `1px 1px 4px #00000040`,
  },

  typography: { useNextVariants: true },
});

export default theme;
