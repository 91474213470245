import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Loading, Spacer } from '@hopdrive/storybook';

//////////////////////// COMPONENT ////////////////////////

export default function LoadingFallback({ message = null, paper = false, text = false }) {
  const cls = useStyles();

  if (paper) {
    return (
      <div className={cls.notifyBoxPaper}>
        <Typography className={cls.notifyText}>{message || `FETCHING DATA`}</Typography>
        <Spacer size='xs' />
        <Loading />
      </div>
    );
  }
  if (text) {
    return (
      <div>
        <Typography className={cls.notifyText}>{message || `FETCHING DATA`}</Typography>
        <Spacer size='xs' />
        <Loading />
      </div>
    );
  }
  return (
    <div className={cls.notifyBox}>
      <Typography className={cls.notifyText}>{message || `FETCHING DATA`}</Typography>
      <Spacer size='xs' />
      <Loading />
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  notifyBox: {
    position: 'relative',
    width: '100%',
    padding: theme?.spacing(4),
    borderBottom: theme?.border?.length && theme?.border[0],
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme?.palette?.background?.paper,
  },
  notifyBoxPaper: {
    position: 'relative',
    width: '100%',
    padding: theme?.spacing(4),
    borderRadius: theme?.shape?.paperRadius,
    background: theme?.palette?.background?.paper,
    boxShadow: theme?.shadow?.medium,
  },
  notifyText: {
    color: theme?.palette?.text?.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 21,
    fontWeight: 500,
    [theme?.breakpoints?.down('sm')]: {
      fontSize: 18,
    },
    [theme?.breakpoints?.down('xs')]: {
      fontSize: 16,
    },
  },
}));
