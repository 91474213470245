//////////////////////// DEPENDENCIES ////////////////////////

import React, { Suspense } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';

import { DataProvider } from './providers/DataProvider';
import { RegionsProvider } from './providers/RegionsProvider';
import { NotificationsProvider } from './providers/NotificationsProvider';
import { UserDetailsProvider } from './providers/UserDetailsProvider';

import theme from './utils/theme';
import TopLevelFallback from './components/Fallbacks/TopLevelFallback';
import DefaultLoadingFallback from './components/Fallbacks/DefaultLoadingFallback';

const Layout = React.lazy(() => import('./components/_app/Layout'));
const Routes = React.lazy(() => import('./Routes'));

//////////////////////// COMPONENT ////////////////////////

function App(props) {
  return (
    <Sentry.ErrorBoundary fallback={<TopLevelFallback />}>
      <DataProvider>
        <UserDetailsProvider>
          <RegionsProvider>
            <NotificationsProvider>
              <MuiThemeProvider theme={theme}>
                <Suspense fallback={<DefaultLoadingFallback />}>
                  <Layout>
                    <Routes />
                  </Layout>
                </Suspense>
              </MuiThemeProvider>
            </NotificationsProvider>
          </RegionsProvider>
        </UserDetailsProvider>
      </DataProvider>
    </Sentry.ErrorBoundary>
  );
}

//////////////////////// EXPORT ////////////////////////

export default App;
